<template>
    <a
        v-if="entry && isExternal"
        :href="entry?.externalUrl"
        :target="target"
        :rel="rel"
        @click="mainStore.toggleMenuOpen(false)"
    >
        {{ label }}
    </a>
    <NuxtLink
        v-else-if="isAnchor"
        :to="{
            hash: `#${link?.anchorId}`,
        }"
    >
        {{ label }}
    </NuxtLink>
    <NuxtLink
        v-else-if="entry && slashedUri"
        :to="localePath(slashedUri, theLocale)"
        :target="target"
        @click="mainStore.toggleMenuOpen(false)"
    >
        {{ label }}
    </NuxtLink>
</template>

<script setup>
import { HOME_URI } from '~/constants/page-uri';

const localePath = useLocalePath();
const mainStore = useMainStore();

const props = defineProps({
    link: {
        type: Object,
        default() {
            return {};
        }
    },
    locale: {
        type: String,
        default: null
    }
});

const { link, locale } = toRefs(props);

const entry = computed(() => link.value?.entry?.[0]);
const isAnchor = computed(() => link.value?.__typename === 'entryLink_anchorLink_BlockType');
const isExternal = computed(() => !!entry.value?.externalUrl);
const target = computed(() => link.value?.targetBlank ? TARGET_BLANK : TARGET_SELF);
const uri = computed(() => {
    if (entry.value?.uri === HOME_URI) {
        return '/';
    }
    return entry.value?.uri;
});
const slashedUri = computed(() => {
    if (uri.value == null || uri.value.startsWith('/')) {
        return uri.value;
    }

    return `/${uri.value}`;
});
const label = computed(() => link.value?.label ?? '');
const rel = computed(() => {
    if (isExternal.value ?? target.value === TARGET_BLANK) {
        return 'noopener noreferrer';
    }

    return '';
});
const { locale: i18nLocale } = useI18n();
const theLocale = computed(() => {
    if (locale.value) {
        return locale.value;
    }

    return i18nLocale.value;
});
</script>

<script>
export const TARGET_SELF = '_self';
export const TARGET_BLANK = '_blank';
</script>
